import { IOrder, State } from "../types/order.types";
import { IProductionOrder, ISalesOrder, ProductionOrderTypes } from "../types/salesOrder.types";

export const restFound = (order: IOrder) => order.compartments.reduce((r, c) => r || c.restFound, false);

export const products = (order: IOrder) => order.compartments.map(c => c.product.name);

export const commentPresent = (order: IOrder) => order.fromItactMessage ||
    order.toCleaningMessage ||
    order.fromCleaningMessage ||
    order.toItactMessage;

export const allowedToUpdatePriority = (state: State) => {
    switch (state) {
    case State.ORDER_RECEIVED:
    case State.CONTAINER_ARRIVED:
    case State.PLANNED:
    case State.READY_FOR_CLEANING:
    case State.ERROR_DURING_CLEANING:
        return true;
    default:
        return false;
    }
};

export const allowedToMarkItactDone = (state: State) => state === State.ITACT_REPAIR;

export const isTruckBlendOrder = (order: ISalesOrder): boolean => order.productionOrders.length >= 2;

export const getFirstEndTimeSlot = (orders: ISalesOrder[]) => {
    const productionOrders = orders.map((salesOrder: ISalesOrder) => salesOrder.productionOrders).flat();
    const timeSlots = productionOrders
        .map((productionOrder: IProductionOrder) => productionOrder.deliveryAt)
        .filter((deliveryAt: string | null) => deliveryAt);

    return timeSlots.length > 0 ? timeSlots[0] : null;
};

export const getFirstTimeSlot = (orders: ISalesOrder[]) => {
    const productionOrders = orders.map((salesOrder: ISalesOrder) => salesOrder.productionOrders).flat();
    const timeSlots = productionOrders
        .map((productionOrder: IProductionOrder) => productionOrder.startDeliveryAt)
        .filter((startDeliveryAt: string | null) => startDeliveryAt);

    return timeSlots.length > 0 ? timeSlots[0] : null;
};

export const getEarliestTimeSlot = (orders: ISalesOrder[]) => {
    const maxDate = new Date("9999-12-31T23:59:59.999Z");
    const productionOrders = orders.map((salesOrder: ISalesOrder) => salesOrder.productionOrders).flat();
    const timeSlots = productionOrders
        .map((productionOrder: IProductionOrder) => productionOrder.deliveryAt)
        .filter((deliveryAt: string | null) => deliveryAt)
        .sort(function compareFn(a, b) {
            return (a || maxDate) > (b || maxDate) ? 1 : (a || maxDate) < (b || maxDate) ? -1 : 0;
        });

    return timeSlots.length > 0 ? timeSlots[0] : null;
};

export const filterOutBlendComponentsInProductionOrder = (order: IProductionOrder) => order.productionOrderType !== ProductionOrderTypes.COMPONENT;
