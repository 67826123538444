
export interface ITank {
    id: number;
    name: string;
    product: string;
    customer?: ICustomer;
    pitId?: number;
    bay?: IBay;
}

export enum BookingType {
    SINGLE = "Single",
    MULTI_ORDER = "Multi-order",
    TRUCK_BLEND = "Truck-blend",
}

export interface IBooking {
    type: BookingType;
}

export interface IBay {
    name: string;
    zone: IZone;
}

export interface IPit {
    id: number;
    name: string;
    tanks: ITank[];
}

export interface IZone {
    id: number;
    name: string;
}

export enum SlotBookingOrderType {
    BULK = "BULK",
    WRAPPED = "WRAPPED",
    CONTAINER = "CONTAINER"
}

export enum OrderAction {
    LOAD= "LOAD",
    UNLOAD = "UNLOAD",
    VULEXTRUCK = "VULEXTRUCK"
}

export enum ProductionOrderTypes {
    BLEND ="BLEND",
    COMMON ="COMMON",
    COMPONENT ="COMPONENT",
}

export interface IProduct {
    materialDescription: string;
    unNumber: string | null;
}

export interface IProductionOrder {
    quantityUnit: string;
    deliveryAt: string | null;
    startDeliveryAt: string | null;
    id: number;
    sapReference: string| null;
    tank: string | null;
    product: IProduct;
    quantity: number;
    adr: boolean;
    storageLocation: StorageLocation;
    productionOrderType: ProductionOrderTypes;
}

export interface ICustomer {
    name: string;
}

export interface ICountry {
    countryCode: string;
}

export interface IManualOrder {
    id: number;
    manualOrderAction: OrderAction;
    destinationEu: boolean | null;
    customer: string;
    orderNumber: string;
    product: string;
    adr: boolean;
    quantity: number;
    storageLocation: StorageLocation;
}

export interface ISalesOrder {
    customerReference: string | null;
    orderType: SlotBookingOrderType;
    orderAction: OrderAction;
    productionOrders: IProductionOrder[];
    containerNumber: string | null;
    sapReference: string;
    customer: ICustomer;
    destination: ICountry;
    overdue: boolean;
    mailSent: boolean | null;
    manualOrderId: number | null;
    distributionChannel: string | null;
    celaneseNumber: string | null;
    oqNumber: string | null;
    cancelled: boolean;
    temperatureNotKnown: boolean;
}

export interface ISalesOrderDTO extends ISalesOrder {
    id: number;
    reservationId: string;
    requiredCertificates: IRequiredCertificate[];
}

export interface IRequiredCertificate {
    name: string;
    orderType: SlotBookingOrderType;
    orderAction: OrderAction;
    materialCode: string;
}

export interface ISalesOrderListFetchParams {
    reference: string;
}

export enum StorageLocation {
    ADPO= "ADPO",
    ALP = "ALP",
    LLH = "LLH"
}
