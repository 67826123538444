import { Col, Row, Tooltip } from "antd";
import * as React from "react";
import { useIntl } from "react-intl";
import {
    formatBoolField,
    formatCheckInState,
    formatCheckInType,
    optionalFormatIso8601DateTime,
    roundToTens
} from "../../../../../../utils/format";
import { InfoField } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoField";
import { InfoLabel } from "../../../customerservicemodal/panes/detailinfopane/sections/infofields/InfoLabel";
import { InfoHover } from "./InfoHover";
import { IProductionOrder, ISalesOrder, SlotBookingOrderType } from "../../../../../../types/salesOrder.types";
import { ICheckIn } from "../../../../../../types/checkIn.types";
import { WeightCalculationWarning } from "../../WeightCalculationWarning";
import { ExemptAdrDisplay } from "../../../../displays/ExemptAdrDisplay";
import {
    filterOutBlendComponentsInProductionOrder,
    getFirstEndTimeSlot,
    getFirstTimeSlot
} from "../../../../../../utils/order";
import { ReactComponent as Warning } from "../../../../../../assets/warning-yellow.svg";
import _ from "lodash";
import { checkInIsBulkFlowSelector } from "../../../../../../store/selectors/checkInOverview.selector";
import { useSelector } from "react-redux";

interface IProps {
    cancelReason?: string;
    productionOrder: IProductionOrder;
    salesOrder: ISalesOrder;
    checkIn: ICheckIn;
    MESSAGES: any;
}

export const SalesOrderInfo = (props: IProps) => {
    const isBulkFlow = useSelector(checkInIsBulkFlowSelector);

    const intl = useIntl();
    const singleCompartmentDistribution = props.checkIn.compartmentDistributions.length === 1 && props.checkIn.compartmentDistributions[0];

    const productionOrders = props.salesOrder.productionOrders.filter(filterOutBlendComponentsInProductionOrder);
    const hasMultiProductionOrdersForSalesOrder = productionOrders.length > 1;

    const getAssignmentNumber = () => {
        return hasMultiProductionOrdersForSalesOrder ? props.salesOrder.sapReference : props.productionOrder.sapReference;
    };

    const getMaterialDescription = () => {
        return hasMultiProductionOrdersForSalesOrder ? intl.formatMessage(props.MESSAGES.multipleProducts) : props.productionOrder.product.materialDescription;
    };

    const getQuantity = () => {
        if (hasMultiProductionOrdersForSalesOrder) {
            return productionOrders.map(po => po.quantity).reduce((prev, next) => prev + next);
        }
        if (singleCompartmentDistribution) {
            const loadAmount = singleCompartmentDistribution && singleCompartmentDistribution.loadAmount || 0;
            const toleranceAdjustedAmount = singleCompartmentDistribution && singleCompartmentDistribution.toleranceLoadAmount || 0;
            return loadAmount + toleranceAdjustedAmount;
        }
        return props.productionOrder.quantity;
    };

    const getUnNumber = () => {
        if (hasMultiProductionOrdersForSalesOrder) {
            const values = productionOrders.map(po => po.product.unNumber).filter(unNumber => !_.isNil(unNumber) && !_.isEmpty(unNumber));
            return Array.from(new Set(values).values()).join();
        } else {
            return props.productionOrder.product.unNumber;
        }
    };

    const hasAdr = () => {
        if (hasMultiProductionOrdersForSalesOrder) {
            return productionOrders.map(po => po.adr).some(value => value);
        } else {
            return props.productionOrder.adr;
        }
    };

    return (
        <div>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.orderType)}/>
                    <InfoField value={formatCheckInType(intl, props.checkIn.checkInReason, props.salesOrder)}/>
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.state)}/>
                    <div>
                        {formatCheckInState(props.checkIn.checkInState, intl)}
                        <InfoHover information={props.checkIn.cancelReason} />
                    </div>
                </Col>
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.reference)}/>
                    <InfoField value={props.salesOrder.sapReference}/>
                </Col>
                {
                    props.salesOrder.orderType !== SlotBookingOrderType.CONTAINER ?
                        <Col span={12}>
                            <InfoLabel text={intl.formatMessage(props.MESSAGES.assignmentNumber)}/>
                            <InfoField value={getAssignmentNumber()}/>
                        </Col>
                        :
                        <Col span={12} />
                }

            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.customer)}/>
                    <InfoField value={props.salesOrder.customer.name}/>
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.unNumber)}/>
                    <InfoField value={getUnNumber()}/>
                </Col>
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.product)}/>
                    <InfoField value={getMaterialDescription()}/>
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.customerReference)}/>
                    <InfoField value={props.salesOrder.customerReference || props.salesOrder.containerNumber}/>
                </Col>
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.tank)}/>
                    <InfoField value={props.productionOrder.tank}/>
                </Col>

            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.amount)}/>
                    <div>
                        {new Intl.NumberFormat("de-DE").format(isBulkFlow ? roundToTens(getQuantity()) : getQuantity())} {props.productionOrder.quantityUnit}
                        {" "}
                        <WeightCalculationWarning />
                    </div>
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.destination)}/>
                    <InfoField value={props.salesOrder.destination.countryCode}/>
                </Col>
            </Row>
            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text="ADR"/>
                    {props.checkIn.exemptAdr ?
                        <ExemptAdrDisplay /> :
                        <InfoField value={formatBoolField(hasAdr(), intl)}/>
                    }
                </Col>
                {props.checkIn.cmrProductName &&
                    <Col span={12}>
                        <InfoLabel text="CMR product name"/>
                        <InfoField value={props.checkIn.cmrProductName}/>
                    </Col>
                }
            </Row>
            {props.checkIn.cmrWeightContainer && (
                <Row className="margin-bottom">
                    <Col span={12}>
                        <InfoLabel text={intl.formatMessage(props.MESSAGES.cmrWeight)}/>
                        <InfoField value={props.checkIn.cmrWeightContainer}/>
                    </Col>
                </Row>
            )}
            {typeof props.checkIn.temperatureProduct === "number" &&
                <Row className="margin-bottom">
                    <Col span={12}>
                        <InfoLabel text={intl.formatMessage(props.MESSAGES.temperatureProduct)}/>
                        <InfoField value={props.checkIn.temperatureProduct + intl.formatMessage(props.MESSAGES.degreesCelsius)}/>
                    </Col>
                </Row>
            }

            <Row className="margin-bottom">
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.timeslot)}/>
                    <Row>
                        <InfoField value={optionalFormatIso8601DateTime(getFirstTimeSlot([props.salesOrder]))}/>
                    </Row>
                </Col>
                <Col span={12}>
                    <InfoLabel text={intl.formatMessage(props.MESSAGES.timeslotEnd)}/>
                    <Row>
                        <InfoField value={optionalFormatIso8601DateTime(getFirstEndTimeSlot([props.salesOrder]))}/>
                        {props.salesOrder.overdue &&
                          <Tooltip title={intl.formatMessage(props.salesOrder.mailSent? props.MESSAGES.mailSent : props.MESSAGES.overDue)} placement="top">
                              <Warning className="margin-third-left" />
                          </Tooltip>
                        }
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
