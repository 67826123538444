import { Checkbox, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import * as React from "react";
import { SyntheticEvent, useEffect, useState } from "react";
import { itactPageOrderUpdateDoneActionCreator } from "../../../store/actions/itact";
import { IOrderFilter, IPageRequest, IPageResponse } from "../../../types/api.types";
import { IOrder, State, StateGroup } from "../../../types/order.types";
import { ItactModal } from "../../components/modals/itactmodal/ItactModal";
import { ItactTable } from "../../components/tables/itacttable/ItactTable";
import { IItactPageLoader, useItactPageLoader } from "../../hooks/pageloaders/useItactPageLoader";
import { ICsvDownloadItactPageLoader, useCsvDownloadItact } from "../../hooks/useCsvDownloadItact";
import { useOrderUpdate } from "../../hooks/useOrderUpdate";

export const ItactTableContainer = () => {
    const itactPageLoader: IItactPageLoader = useItactPageLoader();
    const [update, ordersBeingUpdated] = useOrderUpdate(itactPageOrderUpdateDoneActionCreator);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [clickedOrderId, setClickedOrderId] = useState<number | undefined>();
    const csvDownload: ICsvDownloadItactPageLoader = useCsvDownloadItact();

    useEffect(() => {
        const pageRequest: IPageRequest<IOrderFilter> = {
            pageNumber: itactPageLoader.resource?.page.meta.number || 0,
            filter: {}
        };

        itactPageLoader.loadResource(pageRequest);
    }, []);

    const resource: IPageResponse<IOrder, IOrderFilter> | null = itactPageLoader.resource;

    const itactNeededRenderer = (order: IOrder) => {
        const updateItactNeeded = (e: CheckboxChangeEvent) => update(order.id, { itactNeeded: e.target.checked });
        const onClick = (e: SyntheticEvent) => {
            e.stopPropagation();
            e.preventDefault();
        };
        const beingSaved = ordersBeingUpdated[order.id] && !!ordersBeingUpdated[order.id].itactNeeded;
        const disabled = order.itactDone;

        return beingSaved ? <Spin size="small"/> :
            <Checkbox checked={order.itactNeeded} onChange={updateItactNeeded} disabled={disabled} onClick={onClick}/>;
    };

    const itactDoneRenderer = (order: IOrder) => {
        const updateItactDone = (e: CheckboxChangeEvent) => update(order.id, { itactDone: e.target.checked });
        const onClick = (e: SyntheticEvent) => {
            e.stopPropagation();
            e.preventDefault();
        };
        const beingSaved = ordersBeingUpdated[order.id] && !!ordersBeingUpdated[order.id].itactDone;
        const canToggleItactDoneTrue = order.state === State.ITACT_REPAIR && order.stateGroup === StateGroup.FINISH_UP && order.itactNeeded;
        const canToggleItactDoneFalse = order.itactDone && order.state === State.CLEANED_READY_FOR_PICKUP && order.mostRecentStateTransition.sourceState === State.ITACT_REPAIR;
        const enabled = canToggleItactDoneTrue || canToggleItactDoneFalse;

        return beingSaved ? <Spin size="small"/> : <Checkbox checked={order.itactDone} onChange={updateItactDone} disabled={!enabled} onClick={onClick}/>;
    };

    const onRow = (record: IOrder) => ({
        onClick: () => {
            setClickedOrderId(record.id);
            setModalVisible(true);
        }
    });

    const onModalClose = () => {
        setModalVisible(false);
        itactPageLoader.refresh();
    };

    return (
        <>
            <ItactTable
                csvDownload={(pageRequest: IPageRequest<IOrderFilter>) => csvDownload.requestResource(pageRequest)}
                fetchingPage={itactPageLoader.loadingResource}
                fetchPage={itactPageLoader.loadResource}
                filter={resource ? resource.filter : undefined}
                sorting={resource ? resource.sorting : undefined}
                page={resource ? resource.page : undefined}
                itactNeededRenderer={itactNeededRenderer}
                itactDoneRenderer={itactDoneRenderer}
                onRow={onRow}
            />
            <ItactModal visible={modalVisible} closeModal={onModalClose} orderId={clickedOrderId}/>
        </>
    );
};
